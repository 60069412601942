import React from "react"

import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import { useRamsesData } from "hooks/contentful/pages/useRamsesData"

import Layout from "components/Layout"
import SEO from "components/Seo"
import PageTitle from "components/PageTitle/PageTitle"
import TabbedNav from "components/TabbedNav/TabbedNav"
import RamsesCardA from "components/RamsesCards/RamsesCardA"
import RamsesCardB from "components/RamsesCards/RamsesCardB"

import "../styles/main.scss"
import styles from "../styles/pages/key-studies-ramses.module.scss"

const REFERENCES = [
  "PALFORZIA [package insert]. Lenoir, NC: Greer Laboratories, Inc.",
  "ClinicalTrials.gov website. https://clinicaltrials.gov/ct2/show/NCT03126227. NLM identifier: NCT03126227. February 26, 2018. Accessed September 14, 2020.",
]

const Ramses = ({ data, location }) => {
  const { header, tabs, referencesHeader, cardA, cardB } = useRamsesData(
    data.page
  )

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout
        showSeeResultsMenu={true}
        referencesHeader={referencesHeader}
        references={REFERENCES}
      >
        <SEO title={data.page.title} description={data.page.description} />
        <main className={styles.content}>
          <PageTitle {...header}></PageTitle>
          <TabbedNav hash={location.hash} tabs={tabs} />
          <RamsesCardA {...cardA} />
        </main>
      </Layout>
    </StateProvider>
  )
}

export default Ramses

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Ramses" }) {
      label
      url
      title
      description
      header {
        ...ReferencedTitleFragment
      }
      content {
        ...MenuFragment
      }
      referencesHeader {
        ...ReferencedTitleFragment
      }
    }
  }
`
